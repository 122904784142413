import React, { Component, } from 'react';

import './overview.scss';

class Overview extends Component {
    render() {
        return (
            <div className="overview">
                <div id="intro">
                    <h1>
                        <i>Ghar Mein Shehar Hona</i>: City Housing in a Cultural Matrix<br/>
                        1950 to 2020
                    </h1>

                <p>
                <a href="#part1">
                <img src="/jpg/part1.jpg" alt="From Janata Colony to Janata Colony (imaginary to destroyed)" />
                <b>Part 1</b>. <i>From Janata Colony to Janata Colony</i> (imaginary to destroyed)<br />
                via print and film, love and music, state propaganda and people's archives, five-year plans and Emergency.
                <br/>
                105 mins<br />
                <br/>
                Play Now
                </a>
                </p>
                <p>
                <a href="#part2">
                <img src="/jpg/part2.jpg" alt="From Footpath to Flat (via FSI)" />
                <b>Part 2</b>: <i>From Footpath to Flat</i> (via FSI).<br />
                through the Slum Rehabilitation Authority, Transferable Development Rights, Transit Camps, Monsoons, Rocks, Courts, and four institutional histories.
                <br/>
                107 mins<br />
                <br/>
                <br/>
                Play Now
                </a>
                </p>
                <p className="soon">
                <img src="/jpg/part3.jpg" alt="From Right to Information (Virtual i.e. Real landscapes)" />
                <b>Part 3</b>: <i>From Right to Information</i> (Virtual i.e. Real landscapes)<br />
                via TDR, Cyprus, Speculative Housing, Capital Flight, GBGB Andolan, Multicam Stings, Drone Leaks, .pdf and .xlsx.
                <br/>
                <br/>
                Coming Soon...
                </p>
                <div className="nav">
                    <a href="#about">About</a> | <a href="https://studio.camp" target="_blank">CAMP</a>
                </div>
                </div>
            </div>
        )
    }
}

export { Overview };
