import React, { Component, } from 'react';
import shaka from 'shaka-player'


import { clipDuration } from './playlist';

class DASH extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentClip: null,
        }
        this.tick = this.tick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getCurrentTime = this.getCurrentTime.bind(this);
        this.props.player.registerTick(this.tick)
        if (this.props.track.type === 'audio') {
            this.props.player.dashAudio = this
        } else {
            this.props.player.dash = this
        }
    }

    componentDidMount() {
          // Install built-in polyfills to patch browser incompatibilities.
        shaka.polyfill.installAll();

        // Check to see if the browser supports the basic APIs Shaka needs.
        if (shaka.Player.isBrowserSupported()) {
        // Everything looks good!
            this.initPlayer();
        } else {
            // This browser does not have the minimum set of APIs we need.
            console.error('Browser not supported!');
        }
    }

    componentDidUpdate() { this.update(); }

    componentWillUnmount() {
        console.log('cleanup?')
        this.player && this.player.destroy()
    }

    getCurrentClip() {
        return this.state.currentClip || {}
    }


    computeVolume(clip, currentTime) {
        var volume = 1
        var track = this.props.track

        if ('volumeKeyframes' in clip) {
            var keyframes = clip.volumeKeyframes
            var start = Object.keys(keyframes).filter((p) => { return p <= currentTime })
            var end = Object.keys(keyframes).filter((p) => { return p > currentTime })
            if (!start.length && end.length) {
                start.push(end.pop(0))
                end = []
            }
            if (start.length && end.length) {
                start = start[start.length-1]
                end = end[0]
                var delta = keyframes[end] - keyframes[start]
                var offset = (currentTime - start) / (end - start)
                volume = keyframes[start] + offset*delta
                if (volume < 0) {
                    volume = 0
                } else if (volume > 1) {
                    volume = 1
                }
            } else if (start.length) {
                volume = keyframes[start[start.length-1]]
            } else {
                console.log('wtf', currentTime, start, end, clip.volume)
            }
        } else if ('volume' in clip) {
            volume = Math.max(Math.min(1, clip.volume), 0)
        } else if (track && 'volume' in track) {
            volume = Math.max(Math.min(1, track.volume), 0)
        }
        if(isNaN(volume)) {
            console.log('WTF', currentTime, clip)
        }
        return volume
    }

    freezeRanges(clip) {
        var freezes = clip.freeze
        var duration = clip.duration
        var tp = 0, vp = 0, ranges = [];
        Object.keys(freezes).forEach(freeze => {
            if (freeze > vp) {
                var d = freeze - vp
                ranges.push(['v', tp, tp + d, vp])
                tp += d
                vp += d
            }
            ranges.push(['f', tp, tp+freezes[freeze]])
            tp += freezes[freeze]
        })
        if (vp < duration) {
            var d = duration - vp
            ranges.push(['v', tp, tp + d, vp])
        }
        return ranges
    }


    inFreeze(clip, currentTime) {
        if (!clip.freeze) {
            return false
        }
        var ranges = this.freezeRanges(clip), inFreeze = false
        ranges.some(range => {
            //console.log(range[1], currentTime, range[2])
            if (currentTime >= range[1] && currentTime < range[2]) {
                inFreeze = range[0] === 'f'
                //console.log('current', range, currentTime)
                return true
            }
            return false
        })
        //console.log(currentTime, ranges, inFreeze)
        return inFreeze
    }

    frozenPast(clip, currentTime) {
        var ranges = this.freezeRanges(clip), past = 0
        ranges.some(range => {
            if (range[1] < currentTime) {
                if (range[0] === 'f') {
                    past += range[2] - range[1]
                }
                return false
            }
            return true
        })
        //console.log('past', currentTime, past)
        return past
    }

    updateMediaState() {
        var player = this.props.player
        var element = this.refs.media
        var clip = this.getCurrentClip()

        if (player.dashAudio === this && (player.isSeeking() || player.isWaiting())) {
            element.pause()
        } else {
            if (player.state.paused && !element.paused) {
                element.pause()
            } else if (!player.state.paused && element.paused) {
                element.play()
            }
        }

        var currentTime = player.state.currentTime

        var volume = this.computeVolume(clip, currentTime)
        if (volume !== element.volume && !isNaN(volume)) {
            element.volume = volume
        }
        if ((player.seeked || (player.state.paused && !player.seeking) || player.dashAudio === this) && currentTime >= 0) {
            var src = element.src.split('/').pop()
            var delta = Math.abs(currentTime - element.currentTime)
            if (player.seeked || delta > 0.3) {
                console.log('adjust time', src, element.currentTime, '=>', currentTime, delta)
                element.currentTime = currentTime
            }
        }
    }

    tick(currentTime) {
        var this_ = this;
        var currentClip = null
        var nextClip = null
        var track = this.props.track
        if (currentTime === undefined) {
            currentTime = this.props.player.state.currentTime
        }
        if (track) {
            track.clips.some(clip => {
                var out = clip._position + clipDuration(clip)
                if (currentClip) {
                    nextClip = clip
                    nextClip.prefix = this_.props.player.state.prefix
                    return true
                }
                if (currentTime >= clip._position && currentTime < out) {
                    currentClip = clip
                    currentClip.prefix = this_.props.player.state.prefix
                }
                return false
            })
        }
        if (
            currentClip &&
            currentClip.pause) {
            if (Math.abs(currentTime - (currentClip._position + clipDuration(currentClip))) < 0.1) {
                currentClip.pause = false
                this.props.player.pause()
            }
        }
        if (this.state.currentClip !== currentClip) {
            this.setState({
                currentClip: currentClip,
                nextClip: nextClip
            })
            //console.log('activate', currentTime, currentClip)
        }
    }

    update() {
        if (this.refs.media) {
            this.refs.media.setAttribute('playsinline', '1');
            //this.refs.media.setAttribute('autoplay', '1');
            this.updateMediaState()
        }
    }

    handleClick(event) {
        event.preventDefault();
    }

    getCurrentTime() {
        var clip = this.getCurrentClip()
        if (clip && this.refs.media) {
            return this.refs.media.currentTime
        }
        return undefined
    }

    initPlayer() {
        var this_ = this
        this_.isWaiting = true
        this.player = new shaka.Player(this.refs.media)
        /*
        this.player.configure({
            streaming: {
                bufferingGoal: 120
            }
        })
        */
        this.refs.media.addEventListener('waiting', (event) => {
            console.log('playback waiting', this.player.dash === this ? 'video':'audio')
            this_.isWaiting = true
        })
        this.refs.media.addEventListener('stalled', (event) => {
            console.log('playback stalled', this.player.dash === this ? 'video':'audio')
            this_.isWaiting = true
        })
        this.refs.media.addEventListener('playing', (event) => {
            console.log('resume playback', this.player.dash === this ? 'video':'audio')
            this_.isWaiting = false
        })
        this.refs.media.addEventListener('ended', (event) => {
            console.log('playback ended')
            var player = this.props.player
            if (player.dash === this_) {
                player.ended()
            }
        })

        // Listen for error events.
        this.player.addEventListener('error', this.onErrorEvent)

        // Try to load a manifest.
        // This is an asynchronous process.
        this.player.load(this.props.mpd).then(function() {
            // This runs if the asynchronous load is successful.
            // console.log('The video has now been loaded!');
            this_.update()
        }).catch(this.onError);  // onError is executed if the asynchronous load fails.
    }

    onErrorEvent(event) {
        // Extract the shaka.util.Error object from the event.
        this.onError(event.detail);
    }

    onError(error) {
        // Log the error.
        console.error('Error code', error.code, 'object', error);
    }

    componentWillUnmount() {
        // unmount stuff
        // kill stream hogging...:)
    }

    render() {
        if (this.props.track.type === 'audio') {
            return (
                <div className="audio">
                    <audio ref="media" />
                </div>
            )
        } else {
            var style = {}
            if (this.props.transform) {
                style['transform'] = this.props.transform
            }
            if (this.props.style) {
                Object.keys(this.props.style).forEach(key => {
                    var sKey = key.replace(/-./, (k) => { return k[1].toUpperCase() })
                    style[sKey] = this.props.style[key]
                })
            }
            return (
                <div className="video crop">
                    <video ref="media" onClick={this.handleClick} style={style} width="1920" height="1080" />
                </div>
            )
        }
    }
}


export { DASH };
